@media (max-width: 1160px) {
  .ueberunsblock1 {
    .spalte.spalte.spalte {
      text-align: center;

      p {
        text-align: left;
      }

      a {
        margin: 0 auto 35px;
      }
    }
  }
}

@media (max-width: 1090px) {
  .zustaendig {
    .ueberunsboxen {
      margin-bottom: 50px;

      > div {
        width: 30%;
      }
    }
  }
}

@media (max-width: 1060px) {
  .ansprechpartnercard {
    width: 30%;
  }

  .zustaendig {
    .sonderrow {
      .kommtweg {
        display: none;
      }

      .spalte1,
      .spalte2,
      .spalte3 {
        width: 33%;
      }
    }

    .spalte2 {
      text-align: center;

      img {
        float: none;
      }
    }
  }
}

@media (max-width: 992px) {
  .startseitecarouselbeiwerk {
    display: none;
  }

  h1.startseite {
    font-size: 2rem;
  }

  h2.startseite {
    font-size: 1.7rem;
  }

  .bildstartseite {
    margin: auto;
    padding-top: 25px;
  }

  #sprechzeitmobile {
    bottom: 0px;
    right: -230px;
    width: 250px;
  }

  .sondercard,
  .ansprechpartnercard {
    width: 30%;
  }
}

@media (max-width: 991px) {
  nav {
    a.navbar-brand {
      order: 2;
    }
    button.navbar-toggler {
      order: 1;
    }
    div.navbar-collapse {
      order: 4;
      ul {
        margin-left: 0;
      }
    }
    div#navbarToggler2 {
      display: block;
      flex-basis: 10%;
      flex-grow: unset;
      order: 3;
    }
  }
}

@media (max-width: 980px) {
  .zustaendig {
    .text-right1.text-right1.text-right1.text-right1 {
      text-align: left;
      width: 100%;
    }

    .ueberunsboxen {
      > div {
        width: 45%;
      }
    }
  }
}

@media (max-width: 882px) {
  .sondercard,
  .ansprechpartnercard {
    width: 38%;
  }
}

@media (max-width: 851px) {
  #carousel2 {
    margin-top: -15px;
  }

  .buttonlink.buttonlink.buttonlink.buttonlink {
    font-size: 0.7rem;
  }

  footer {
    .container {
      padding-bottom: 15px;
    }

    .flexi {
      .adresse {
        width: 40%;
      }

      .sprechtag {
        margin: 0 30%;
        width: 40%;
      }

      .logo {
        margin: 0 10%;
        width: 30%;
      }
    }
  }
}

@media (max-width: 800px) {

  .buttonlink.buttonlink.buttonlink.buttonlink {
    font-size: 1rem;
    margin-bottom: 20px;
    text-align: center;
    width: 90%;
  }

  .suchenseite {

    .input-group-btn {
      .buttonlink.buttonlink.buttonlink.buttonlink.buttonlink {
        margin-bottom: 0;
        text-align: center;
        padding: 1px 25px 1px 15px;

        width: 90%;
      }
    }
  }

  .zustaendig {
    .sonderrow {
      .buttonlink.buttonlink.buttonlink.buttonlink {
        font-size: 0.9rem;
        padding: 1px 10px 1px 10px;
        width: 100%;
      }
    }
  }

  .block1text {
    max-width: 45%;
    flex: 0 0 45%;
  }

  .block1bild {
    max-width: 40%;
    flex: 0 0 40%;
  }

  #sprechzeitmobile {
    bottom: 0px;
    right: -160px;
    width: 250px;
  }

}

@media (max-width: 780px) {
  .ueberunsblock1 {
    a.buttonlink.buttonlink.buttonlink {
      font-size: 0.7rem;
      padding: 1px 20px 1px 10px;
    }
  }
}

@media (max-width: 769px) {
  nav.navbar {
    flex: 0 0 95%;
    margin-left: 15px;
    max-width: 95%;
  }

  .sprechtagimgwrap.offset-md-3 {
    margin-left: 55%;
  }

  #sprechzeitmobile {
    bottom: 0px;
    right: 50px;
    width: 250px;
  }

  .col-md-12.wiewirunsverstehen {
    padding: 0;

    .container {
      margin: 0;
      max-width: 100%;

      a.buttonlink.buttonlink.buttonlink {
        font-size: 0.8rem;
      }
    }
  }

  .suchenseite,
  .impressum,
  .datenschutz,
  .ueberunsseite,
  .stellensuche,
  .kontaktseite,
  .wohnungssucheseite,
  .mieterserviceseite {
    .col-md-7.hauptspalte {
      flex: 0 0 62%;
      margin-left: 0;
      max-width: 62%;
      width: 62%;
    }

    .rechtespalte {
      display: block;
      flex: 0 0 36%;
      max-width: 36%;
      width: 100%;

      img,
      .fragenbeantworten {
        height: auto;
        margin: 0 0 25px;
        width: 100%;
      }

      .fragenbeantworten {
        text-align: center;
      }
    }
  }

  .ueberunsseite {

    .col-lg-6 {
      flex: 0 0 50%;
      width: 50%;
    }

    .ueberunsstellogo {
      text-align: center;

      img {
        width: 50%;
      }
    }
  }

  .mediumtextcenter.mediumtextcenter.mediumtextcenter {
    text-align: center;

    a {
      margin: auto;
    }
  }

  .zustaendig {

    .sonderrow,
    .ueberunsboxen {
      flex: 0 0 100%;
      justify-content: space-around!important;
      max-width: 100%;
      margin-left: 0;
      width: 100%;
    }
  }

  .suchenseite {
    ul {
      margin-left: 0;
    }
  }

  footer {
    padding-bottom: 50px;
  }
}

@media (max-width: 730px) {
  .buttonlink.buttonlink.buttonlink.buttonlink {
    font-size: 1rem;
    margin-bottom: 20px;
    text-align: center;
    width: 70%;
  }
  .block1text {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .block1bild {
    margin-top: 25px;
    max-width: 100%;
    flex: 0 0 100%;
  }

  .suchenseite, 
  .stellensuche,
  .kontaktseite,
  .wohnungssucheseite,
  .mieterserviceseite {
    .col-md-7.hauptspalte {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
    }

    .rechtespalte {
      align-items: center;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;

      > div,
      img,
      .fragenbeantworten {
        margin-bottom: 25px;
        margin-right: 0 !important;
        width: 48%;
      }

      .fragenbeantworten {
        text-align: center;
      }
    }
  }

  .ueberunsseite {
    .col-md-7.hauptspalte {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
    }

    .rechtespalte {
      align-items: center;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;

      img {
        margin-bottom: 25px;
        margin-right: 0 !important;
        width: 32%;
      }

      > div {
        text-align: center;
        width: 32%;

        img {
          margin: auto;
          width: 90%;
        }
      }
    }
  }
  .ueberunsblock1 {
    .spalte {
      text-align: center;
    }

    a.buttonlink.buttonlink.buttonlink {
      font-size: 0.7rem;
      margin: auto;
      padding: 1px 25px 1px 15px;
    }
  }

  .mediumtextcenter {
    a.buttonlink.buttonlink.buttonlink {
      font-size: 0.7rem;
      margin: auto;
      padding: 1px 25px 1px 15px;
    }
  }

}

@media (max-width: 650px) {
  header {
    img:nth-of-type(2) {
      width: 100px;
    }
  }

  .pgia-gallery-thumbnails {
    height: 60px;

    a {
      height: 60px;

      img {
        height: 60px;

      }
    }
  }

  .zustaendig {
    .ueberunsboxen {
      > div {
        width: 100%;

        img {
          width: 60%;
        }
      }
    }

    .sonderrow {
      flex-wrap: wrap;

      .spalte1,
      .spalte2,
      .spalte3 {
        width: 100%;
      }
    }
  }

  .sondercard,
  .ansprechpartnercard {
    width: 45%;
  }


  .stellensuche,
  .kontaktseite,
  .wohnungssucheseite,
  .mieterserviceseite,
  .ueberunsseite {
    .rechtespalte {
      img {
        margin-bottom: 25px;
        margin-right: 0 !important;
        width: 48%;

        &.bild1 {
          border: none !important;
          order: 1;
        }

        &.bild2 {
          order: 3;
        }

        &.bild3 {
          order: 2;
        }

        &.bild4 {
          order: 5;
        }
      }

      > div {
        text-align: center;
        width: 48%;

        img {
          margin: auto;
          width: 90%;
        }
      }
    }

    .buttonlink.buttonlink.buttonlink.buttonlink {
      font-size: 0.6rem;
    }
  }

}

@media (max-width: 580px) {
  footer {

    .flexi {
      align-items: center;

      .adresse {
        width: 60%;
      }

      .sprechtag {
        margin: 0 10%;
        width: 70%;
      }

      .logo {
        margin: 0 0;
        width: 30%;
      }
    }
  }
}

@media (max-width: 560px) {
  .ueberunsblock1 {
    .spalte.spalte.spalte {
      flex: 0 0 100%;
      margin-bottom: 35px;
      width: 100%;

      img {
        width: 50%;
      }
    }
  }

  .stellensuche,
  .kontaktseite,
  .wohnungssucheseite,
  .mieterserviceseite {
    .col-md-7.hauptspalte {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
    }

    .rechtespalte {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;

      > div,
      img,
      .fragenbeantworten {
        margin-bottom: 25px;
        margin-right: 0 !important;
        width: 100%;
      }

      .fragenbeantworten {
        text-align: center;
      }
    }
  }

  .sondercard,
  .ansprechpartnercard {
    margin: 10px auto!important;
    width: 80%;
  }

  .ansprechpartner {
    main {
      h1,
      h2 {
        text-align: center;
      }
    }
  }

}

@media (max-width: 500px) {
  header {
    img:nth-of-type(2) {
      width: 70px;
    }
  }

  .suchenseite .col-md-7.hauptspalte,
  .impressum .col-md-7.hauptspalte,
  .datenschutz .col-md-7.hauptspalte,
  .ueberunsseite .col-md-7.hauptspalte,
  .stellensuche .col-md-7.hauptspalte,
  .kontaktseite .col-md-7.hauptspalte,
  .wohnungssucheseite .col-md-7.hauptspalte,
  .mieterserviceseite .col-md-7.hauptspalte {
    flex: 0 0 100%;
    margin-left: 0;
    max-width: 100%;
    width: 100%;
  }

  .suchenseite .rechtespalte,
  .impressum .rechtespalte,
  .datenschutz .rechtespalte,
  .ueberunsseite .rechtespalte,
  .stellensuche .rechtespalte,
  .kontaktseite .rechtespalte,
  .wohnungssucheseite .rechtespalte,
  .mieterserviceseite .rechtespalte {
    display: block;
    flex: 0 0 100%;
    margin-left: 0;
    max-width: 100%;
    width: 100%;
  }

  footer {
    padding-bottom: 0;

    .flexi {
      align-items: center;

      .adresse {
        width: 100%;
      }

      .sprechtag {
        margin: 0 0%;
        width: 90%;
      }

      .logo {
        margin: 0 45px;
        width: 60%;
      }
    }

    .col-lg-12 {
      margin: 15px -20px;
      width: calc(100% + 40px);

      .col-lg-6 {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        flex: 0 0 100%;
        width: 100%;

        a {
          display: inline-block;
          margin-bottom: 15px;
          width: 48%;

          &:nth-child(2n){
            text-align: right;
          }
        }
      }
    }
  }
}


@media (max-width: 450px) {
  header {
    img:nth-of-type(2) {
      display: none;
    }
  }

  .ueberunsseite {
    .rechtespalte {
      img {
        margin-bottom: 25px;
        margin-right: 0 !important;
        width: 100%;

        &.bild1 {
          border: none !important;
          order: 1;
        }

        &.bild2 {
          order: 3;
          z-index: 10;
        }

        &.bild3 {
          order: 2;
        }

        &.bild4 {
          order: 5;
        }
      }

      > div {
        text-align: center;
        width: 100%;

        img {
          margin: 0 auto 25px;
          width: 90%;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .buttonlink.buttonlink.buttonlink.buttonlink {
    font-size: 1rem;
    margin-bottom: 20px;
    text-align: center;
    width: 90%;
  }
}